import React, { useEffect, useState } from "react"
import { Link, withPrefix } from "gatsby"
import { motion } from "framer-motion"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled("div")`
  //width: clamp(400px, 50vw, 960px);
  margin: 0 auto;
  margin-bottom: 240px;
  position:relative;
  top: 160px;
  padding-left: clamp(36px, 15vw, 250px);
  padding-right: clamp(36px, 15vw, 250px);
  counter-reset: section;

  h3 {
    margin-top: 24px;
    counter-increment: section;
    &:before{
      content: counter(section) ". ";
    }
  }
  h4{
    margin-bottom: 24px;
    margin-top: 24px;
    padding-top: 12px;
    text-transform: uppercase;
    border-top: 1px solid hsla(22, 100%, 50%, 0.3);
  }
  & span.link {
    display: block;
    margin-top: 48px;
  }
  section {
    display: ${props => props.inApp ? "flex" : "grid"};
    flex-direction: column;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 64px auto;
    margin-bottom: 64px;
    grid-column-gap: 64px;
    line-height: 1.6;
    position: relative;
    @media(max-width: 768px){
      grid-template-columns: 1fr;
      grid-auto-flow: column;
    }
    & > h3{
      scroll-margin-top: 100px;
      grid-row: 1/2;
      grid-column: 1/-1;
      text-align: left;
    }
    & > div{
      grid-row: 2/3;
      grid-column: 2/3;
      & li {
        line-height: 1.6;
        margin-bottom: 12px;
      }
      @media(max-width: 768px){
        grid-column: 1/-1;
        grid-row: 2/3;
      }
    }
    & ul > li > ul {
      padding-left: 24px;
    }
    & > img{
      grid-row: 2/3;
      grid-column: 1/3;
      max-width: ${props => props.inApp ? 'min-content' : '30%'};
      height: auto;
      object-fit: contain;
      position: ${props => props.inApp ? "static" : "sticky" };
      top: 100px;
      margin-top: ${props => props.inApp ? '12px' : 0 };
      margin-bottom: ${props => props.inApp ? '24px' : 0 };
      //right: 0;
      z-index: 3;
      transition: max-width 0.2s ease-out;
      transform-origin: top right;
      object-position: top left;
      width: 100%;
      box-shadow: 0 12px 24px 0 rgba(250, 169, 122, 0.45);
      &.fullsize{
        grid-column: 1/3;
        width: auto;
        max-width: clamp(400px, 100%, 960px);
        max-height: calc(80vh - 100px);
      }
      @media(max-width: 768px){
        grid-column: 1/-1;
        grid-row: 3/4;
        margin-top: 24px;
      }
    }
  }
`

const HelpPage = () => {
  const [InApp, setInApp] = useState(false)

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("inapp")) {
      setInApp(urlParams.get("inapp") !== "false");
    }
  },[InApp])

  const enlargeImage = (e) => {
    e.target.classList.toggle('fullsize')
  }

  return (
    <Layout navForced>
      <SEO title={"Sheetplanner - Help"}/>
      <Content inApp={InApp}>
        <main>
          
          <section>
            <h3 id={"Welcome"}>Welcome to SheetPlanner</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/WelcomeWindow.png")} />
            <div>
              <p>The first time you open SheetPlanner, there is an introductory slideshow that gives an overview of the application. At the end of this overview you can select to:</p>
              <ul>
                <li>Use the Free Outliner </li>
                <li>Start a Free Trial</li>
                <li>Unlock the Full App</li>
                <li>Restore a Purchase</li>
              </ul>
              <p>Click <strong>Learn More</strong> to go to the SheetPlanner website.</p>
              <p>Topics marked with * in the help are only available in the Full App.</p>
            </div>
          </section>
          
          <section>
            <h3 id="TemplateChooser">The Template Chooser</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/TemplateChooser.png")} />
            <div>
              <p>When you start SheetPlanner without an active document or create a new document, the <strong>Template Chooser</strong> opens. Scroll through the list of pre-defined templates, or choose a filter on the sidebar to view templates of a particular type. Click the thumbnail of the template you want, and then click <strong>Choose</strong>.</p>
              <p>You can update your SheetPlanner preferences so that when you create a new document it will either open the Template Chooser, use a default template you specify, or create a blank document. See <a href="#preferences">Preferences</a> for more details.</p>
              <h4>Custom Templates</h4>
              <p>Save any SheetPlanner document as a custom template. Within the document, go to <strong>File &gt; Save as Template...</strong>. Custom templates are listed under My Templates in the Template Chooser sidebar. From the Template Chooser, right-click on a custom template to rename or delete it.</p>
              <h4>Saving and Syncing</h4>
              <p>By default documents are saved on iCloud Drive, and are synced to other devices logged in to your iCloud account. You can also save to a local folder if you prefer.</p>
            </div>
          </section>

          <section>
            <h3 id="Window">The SheetPlanner Window</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/MainWindow.png")} />
            <div>
              <p>The SheetPlanner window has a number of views available for you to choose from.</p>
              <p>You can change which panels to view from <a href="#Toolbar">The Toolbar</a>.</p>
            </div>
          </section>

          <section>
            <h3 id="Sidebar">The Sidebar</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/Sidebar.png")} />
            <div>
              <p>A SheetPlanner document can have multiple Folders and Sheets. The Sidebar allows you to view and organize them. </p>
              <p>Built-in Folders include:</p>
              <ul>
                <li>All Sheets</li>
                <li>Recent Sheets</li>
                <li>Favorite Sheets</li>
                <li>Deleted Sheets</li>
              </ul>
              <p>Go to the <strong>Sheet </strong>menu to create, rename, or delete Folders and Sheets.</p>
              <p>The bottom of the Sidebar lists all Sheets in the document, which can be arranged into Folders.</p>
              <p>Click the Star next to a Sheet to add it to Favorites.</p>
              <p>Right-click a Sheet to perform other functions.</p>
            </div>
          </section>
          
          <section>
            <h3 id="Toolbar">The Toolbar</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/Toolbar.png")} />
            <div>
              <p>The SheetPlanner toolbar allows you to quickly access many of the main functions.</p>
              <ul>
                <li><strong>Add</strong>: Click <strong>+</strong> to add a new row. Click the arrow to choose to add either a new row or a new column before or after the currently selected one. You can also add a new folder or sheet via this menu.</li>
                <li><strong>Indent</strong>: Indents the currently selected row. This makes the current row subordinate to the one above it.</li>
                <li><strong>Outdent</strong>: Outdents the currently selected row. This promotes the current row in the hierarchy.</li>
                <li><strong>Focus</strong>: Hide all but the currently selected row and any sub-tasks. When you are in Focus mode, the currently selected focus item is shown in a bar in the Topic header.</li>
                <li>
                  <strong>Completed</strong>: Click to display a menu for how to display completed tasks:
                  <ul>
                    <li><strong>Show Completed</strong>: Show both completed and incomplete tasks.</li>
                    <li><strong>Show Completed Today</strong>: Show tasks completed today, and incomplete tasks. Those completed on previous days are hidden.</li>
                    <li><strong>Hide Completed</strong>: Show only incomplete tasks. All completed tasks are hidden.</li>
                  </ul>
                </li>
                <li>
                  <strong>Connect*</strong>: Add connections to denote dependencies between items. When two or more rows are selected, they can be connected so that a change in the Start, Finish, or Completion date in one adjusts a date of the other. Select two or more rows and click the down-arrow to choose the dependency type:
                  <ul>
                    <li><strong>Finish to Start</strong>: Links the Finish date of the first selected item to the Start date of the next. The first item must end before the second one starts; the items can not overlap. This is the most common type of dependency, and the default selection if you click <strong>Connect</strong>.</li>
                    <li><strong>Start to Finish</strong>: When the first item starts, the second one can end and the items will overlap.</li>
                    <li><strong>Start to Start</strong>: When the first item starts, the second one can start and the items may overlap.</li>
                    <li><strong>Finish to Finish</strong>: When the first item ends, the second one can end and the items may overlap.</li>
                  </ul>
                </li>
                <li>
                  <strong>Share</strong>: Click to display a menu with sharing options:
                  <ul>
                    <li><strong>Share File</strong>: Start to share the document with other people, enabling collaboration.</li>
                    <li><strong>Show/Hide Highlights</strong>: When highlights are hidden, changes on other devices are briefly highlighted; when highlights are shown, the change highlights remain visible.</li>
                    <li><strong>Send a Copy</strong>: A sub-menu of ways to send a copy of the document to others. These don't sync changes.</li>
                  </ul>
                </li>
                <li><strong>Views*</strong>: Show or hide the Outline, Timeline, Month, and Year views. You can have any combination of views showing at any time. Hold the Option key when clicking a button to show only that view.</li>
                <li><strong>Search</strong>: Enter text you want to search for. Any cells that contain the entered text are displayed in the Outline and Timeline views.</li>
                <li><strong>Inspect</strong>: Open the <a href="#Inspector">The Inspector</a> panel.</li>
              </ul>
              <p>Additional buttons that you can add by customizing the toolbar include:</p>
              <ul>
                <li><strong>Expand/All</strong>: The left button expands the currently selected row. The right button expands all nested rows in the document.</li>
                <li><strong>Collapse/All</strong>: The left button collapses the currently selected row. The right button collapses all nested rows in the document.</li>
                <li><strong>Filter Cell*</strong>: Create a new smart filter based on the currently selected cell. See <a href="#Filters">Filters</a> for more details.</li>
                <li><strong>Fill Down</strong>: With multiple cells selected, fills the contents from the top cell into the others.</li>
                <li><strong>Orientation*</strong>: Show the currently selected Views in either a Horizontal or Vertical orientation.</li>
              </ul>
            </div>
          </section>
          
          <section>
            <h3 id="Inspector">The Inspector</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/Inspector.png")} />
            <div>
              <p>The Inspector panel allows you to customize the appearance of the document and changes depending on the context of the selected cell or panel. This list provides a general overview of the available options.</p>
              
              <h4>Cell</h4>
              <ul>
                <li><strong>Value</strong>: All column types have calculations of some sort. For example, with Date columns, if a parent date field is selected, you can automatically calculate the <em>planned</em> Start and Finish Date based on dates in the child rows, or calculate the value for all rows of the Duration column. </li>
                <li><strong>Notify</strong>: If you selected a date cell, select to add a notification when this item is due, then specify how long before the deadline you want to be notified.</li>
                <li><strong>Changed</strong>: If a cell has been edited, the date, author, and device where the change occurred will be displayed.</li>
              </ul>
              
              <h4>Column</h4>
              <ul>
                <li><strong>Name</strong>: The name of the selected column and can only be changed for custom columns.</li>
                <li><strong>Type</strong>: Change what kind of content the cells display.
                  <ul>
                    <li><strong>Text</strong>: Select to show text content. </li>
                    <li><strong>Number</strong>: Select to show numerical entries and choose how they should be formatted.</li>
                    <li><strong>Progress</strong>: Select to show a progress indicator for the item and choose how the progress is displayed.</li>
                    <li><strong>Date</strong>: Select to show a date and time format.</li>
                    <li><strong>Time Interval</strong>: Select to show a time interval.</li>
                    <li><strong>Symbol</strong>: Select to show symbols and choose the kind of symbol and how they are displayed.</li>
                    <li><strong>Picture</strong>: Select to show a picture and choose how they are displayed.</li>
                    <li><strong>List</strong>: Select to show a pre-defined list of options to select from.</li>
                    <li><strong>People</strong>: A special kind of List, used for the built-in People column, that includes collaborators on a document, plus custom people. Colors can be assigned to each person.</li>
                    <li><strong>Button</strong>: Used for Successor and Predecessor column types and is not applicable to a custom column.</li>
                  </ul>
                </li>
                <li><strong>Auto-Enter</strong>: Select whether content in this column is automatically generated and the formatting.</li>
              </ul>
              
              <h4>Row</h4>
              <ul>
                <li><strong>Name</strong>: The current row topic name.</li>
                <li><strong>Start</strong>: Select to assign the <em>planned</em> start date, and then select the date from the calendar pop-up.</li>
                <li><strong>Finish</strong>: Select to assign the <em>planned</em> finish date, and then select the date from the calendar pop-up.</li>
                <li><strong>Completed</strong>: Select to assign the completion date; the date automatically fills in when the topic is marked as completed.</li>
                <li><strong>Progress</strong>: Enter the percent of progress completed.</li>
                <li><strong>Duration</strong>: Automatically calculates the time between the <em>planned</em> Start and Finish dates.</li>
              </ul>
              <h4>Predecessors and Successors</h4>
              <ul>
                <li>If this item has dependencies, they are listed here. Click the Predecessor or Successor to go to that item.</li>
              </ul>
              
              <h4>Style</h4>
              <ul>
                <li><strong>For</strong>: Select the scope of the style; whether the change will affect all other rows of this rank or if this row should have a custom style. Each indentation level can have its own shared style.</li>
                <li><strong>Font Family</strong>: Select the font.</li>
                <li><strong>Face &amp; Size</strong>: Select the text style and size.</li>
                <li><strong>Style &amp; Color</strong>: Select the text style and color.</li>
                <li><strong>Apply to Topic only</strong>: Select to apply this style to the Topic column only. If unselected, this style applies to all columns.</li>
              </ul>
              
              <h4>Outline</h4>
              <ul>
                <li><strong>Theme</strong>: Select a color theme for the Outline view.</li>
                <li><strong>Finish Date</strong>:
                  <ul>
                    <li><strong>Carry forward</strong>: Select to set all past-due items to today.</li>
                    <li><strong>Italicize</strong>: Select to display all carried-over items in italics.</li>
                  </ul>
                </li>
              </ul>
              
              <h4>Timeline</h4>
              <ul>
                <li><strong>Scale</strong>: Choose the scale of each column: days, weeks, months, quarters, or years.</li>
                <li><strong>Include Topic</strong>: Select to display the topic after each timeline bar.</li>
                <li><strong>Use Custom Colors</strong>: Select to use a color of your choice to denote Complete and Incomplete items.</li>
              </ul>
              
              <h4>Calendar</h4>
              <ul>
                <li><strong>Include parents</strong>: Select to include all parent rows in the Calendar view.</li>
                <li><strong>Synchronize selection</strong>: Select to change the behavior of the Calendar view. If checked, selecting a cell in any view will change the selection in the calendar views; otherwise the calendar has independent selection.</li>
              </ul>
            </div>
          </section>


          <section>
            <h3 id="OutlineView">Outline View</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/OutlineView.png")} />
            <div>
              <p>The Outline View is the main view for working in SheetPlanner and should look and feel familiar if you’ve used an outliner or spreadsheet, but includes powerful formatting functions to make your planning easy, down to the last detail. </p>
              <p>Click or arrow around to select cells. Click on a selected cell, or press Enter, to edit the cell value. Some cell types include a button to pick a value, e.g. for a Date cell you can type a date or click the button to show a date picker.</p>

              <p>From the Outline View you can:</p>
              <motion.img onTap={enlargeImage} alt="" src={withPrefix("/OutlineViewRowMenu.png")} />
              <ul>
                <li>Add or remove rows and columns.</li>
                <li>Use <a href="#Inspector">The Inspector</a> to change properties.</li>
                <li>Right-click a row to access the Row Menu for more organization options.</li>
              </ul>
            </div>
          </section>


          <section>
            <h3 id="TimelineView">Timeline View*</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/TimelineView.png")} />
            <div>
              <p>The Timeline View shows a detailed chart to see your tasks scheduled over time, allowing you to view the start and end dates in one simple view.</p>
              <p>Click and drag a start or end date for any task; the dates automatically adjust in the Outline View. You cannot adjust the start and end dates for a parent row that has dates calculated from its child rows.</p>
              <ul>
                <li>Hold Shift or Command to select multiple rows and drag them all at once.</li>
                <li>Add connections to denote dependencies between items. Select two or more rows and either right-click and choose <strong>Connect</strong> or click <strong>Connect</strong> in the <strong>Toolbar</strong>.</li>
              </ul>
            </div>
          </section>
          
          <section>
            <h3 id="MonthView">Month View*</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/MonthView.png")} />
            <div>
              <p>The Month View shows your project plan on a monthly calendar, allowing you to see at-a-glance what tasks are due during the month.</p>
              <p>In Month View you can:</p>
              <ul>
                <li>Hover over an item to view a summary.</li>
                <li>Click once on an item to highlight its start and end points.</li>
                <li>Double-click on an item to open a window where you can view and adjust details about the task including its name, start, finish, and completed dates.</li>
                <li>Click on the box next to a task to mark it complete.</li>
              </ul>
            </div>
          </section>

          <section>
            <h3 id="YearView">Year View*</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/YearView.png")} />
            <div>
              <p>The Year View shows your tasks on a yearly calendar, allowing you to see at-a-glance what tasks are due during the year. </p>
              <p>Click on a highlighted day to see the tasks associated with that day and mark tasks as completed.</p>
            </div>
          </section>

          <section>
            <h3 id="Filters">Filters*</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/FilterBar.png")} />
            <div>
              <p>There are three ways to create Filters in SheetPlanner:</p>
              <ul>
                <li>Use the <strong>Search</strong> field in the toolbar, and then click <strong>Save</strong> to convert the search text into a filter.</li>
                <li><strong>Filter by Cell Value</strong>: With a cell selected, either click <strong>Filter Cell</strong> in <a href="#Toolbar">The Toolbar</a> or go to <strong>Filter &gt; Filter by Cell Value</strong>.</li>
                <li><strong>Create a Smart Filter</strong>: Go to <strong>Filter &gt; New Smart Filter</strong> to open the <strong>Filter Bar</strong>.</li>
              </ul>
              <ul>
                <li>Select the filter criteria you want from the available options. </li>
                <li>Click <strong>+</strong> to add another line to your criteria. </li>
                <li>Click <strong>–</strong> to remove a line.</li>
                <li>Click <strong>Save</strong> when you’re done and give the filter a name. All your saved filters are shown in the Filter Bar above the SheetPlanner document. </li>
                <li>Click a previously saved filter name to filter the active document, or click the <strong>X</strong> next to the filter name to no longer filter the document.</li>
                <li>Right-click a filter name to see more options.</li>
              </ul>
            </div>
          </section>

          <section>
            <h3 id="NotesAndLinks">Notes and Links</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/Notes.png")} />
            <div>
              <p>You can add Notes and Links to any row in SheetPlanner.</p>
              <h4>Notes </h4>
              <ul>
                <li>Use Notes to include more information about the cell. </li>
                <li>While editing a Topic, click the Note icon to enter a note in the cell.</li>
                <li>Right-click the row and select <strong>Show Note in New Tab</strong> to open a tab that displays the note, making it easy to edit long entries. </li>
              </ul>

              <h4>Links</h4>
              <motion.img onTap={enlargeImage} style={{maxWidth: '100%'}} alt="" src={withPrefix("/Link.png")} />
              <ul>
                <li>Right-click a row and select <strong>Link to File...</strong> and then select the file.</li>
                <li>Right-click a row and select <strong>Link to Website...</strong> and then enter the URL.</li>
                <li>Right-click the row and select <strong>Show Row Links in New Tab</strong> to open a tab that includes previews of all the sites and files you’ve linked to in the row.</li>
              </ul>
            </div>
          </section>

          <section>
            <h3 id="WindowsAndTabs">Windows and Tabs*</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/ViewAllTabs.png")} />
            <div>
              <p>Tabs enable you to see multiple views of the same document. Use Tabs to:</p>
              <ul>
                <li>Open <strong>Links</strong> and <strong>Notes</strong> in separate tabs to view their information.</li>
                <li>Drag a tab to a second screen to see multiple views at once. </li>
                <li>Right-click a previously-saved <strong>Filter</strong> or <strong>Focus</strong> name to open the results in a new tab. </li>
              </ul>
              <h4>Navigating Between Tabs</h4>
              <ul>
                <li>Press <strong>Ctrl + Tab</strong> and <strong>Ctrl + Shift + Tab</strong> to move forward and backward through your open tabs.</li>
                <li>See thumbnails of all your tabs with <strong>View &gt; Show All Tabs</strong>. Click a thumbnail to make it the active tab or click <strong>+</strong> to add a new tab.</li>
              </ul>
            </div>
          </section>

          <section>
            <h3 id="PrintPreview">Print Preview</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/PrintPreview.png")} />
            <div>
              <p>When you choose to print your document, the window displays a preview of the page layout and the Inspector shows various printing options.</p>
              <p>In the preview, click row and column headers to choose what will be printed.</p>
            </div>
          </section>
          
          <section>
            <h3 id="Preferences">Preferences</h3>
            <motion.img onTap={enlargeImage} alt="" src={withPrefix("/PreferencesGeneralFull.png")} />
            <div>
              <p>Configure your preferences to personalize how you work in SheetPlanner.</p>
              <h4>General</h4>
              <ul>
                <li><strong>New document or sheet</strong>: Choose what should happen when you open a new document:
                  <ul>
                    <li><strong>Choose a template</strong>: Open <a href="#TemplateChooser">The Template Chooser</a> in order to select any pre-defined template.</li>
                    <li><strong>Use template</strong>: Specify a template to use every time.</li>
                    <li><strong>Make a blank document/sheet</strong>: Open a blank document or sheet.</li>
                  </ul>
                </li>
                <li><strong>Tab and Shift-Tab</strong>: Choose the behavior of the Tab key:
                  <ul>
                    <li><strong>Indent or Outdent</strong>: Tab indents/outdents the current row.</li>
                    <li><strong>Move Right or Left</strong>: Tab moves the cursor to the next column.</li>
                  </ul>
                </li>
                <li><strong>Return and Shift-Return</strong>: Choose the behavior of the Return key:
                  <ul>
                    <li><strong>Insert Row After or Before</strong>: Return inserts a new row.</li>
                    <li><strong>Move Down or Up</strong>: Return moves the cursor to the next row. If you’re at the last row, Return adds a new row after the current one.</li>
                  </ul>
                </li>
                <li><strong>Dock icon badge</strong>: Choose what the SheetPlanner icon in the dock should display.</li>
                <li><strong>Guidance</strong>: Reset the guidance hints so they show again.</li>
              </ul>

              <h4>Defaults</h4>
              <motion.img style={{maxWidth: '100%'}} onTap={enlargeImage} alt="" src={withPrefix("/PreferencesDefaultsFull.png")} />
              <ul>
                <li><strong>Default completed</strong>: Choose how the Completion checkbox appears next to each topic and select whether to Strikethrough completed topics.</li>
                <li><strong>Default theme</strong>: Choose which theme to apply to new documents.</li>
                <li><strong>Editing cell color</strong>: Choose the color for the cell you’re editing.</li>
                <li><strong>Default font</strong>: Choose the default font.</li>
                <li><strong>Default text color</strong>: Choose the default text color.</li>
                <li><strong>Finish date</strong>: Choose whether past-due items should carry forward to the current date and if they should be italicized.</li>
                <li><strong>Timeline defaults</strong>: Choose the default appearance for the Timeline. </li>
                <li><strong>Calendar defaults</strong>: Choose the default behavior for the Calendar. </li>
              </ul>
            </div>
          </section>

        </main>
        <Link to="/"><span className={"link"}>Go back to the homepage</span></Link>
      </Content>
    </Layout>
  )
}

export default HelpPage
